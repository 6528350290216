import { useState } from "react";
import {
  Typography,
  Table,
  Space,
  TablePaginationConfig,
  Card,
  Input,
  Button,
  Form,
  Modal,
} from "antd";
import dayjs from "dayjs";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FilterValue } from "antd/lib/table/interface";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useNavigate } from "react-router-dom";

import CustomLayout from "../components/CustomLayout";
import {
  useDeleteQuoteListingMutation,
  useGetQuoteListingQuery,
} from "../hooks/graphql/graphql";
const { Title } = Typography;
const { confirm } = Modal;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

const Quotes = () => {
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [varPage, setVarPage] = useState(0);
  const navigate = useNavigate();
  const {
    data: quotes,
    loading,
    refetch,
  } = useGetQuoteListingQuery({
    variables: {
      searchKeyword: searchInput.length > 0 ? searchInput : "",
      pageNumber: varPage,
    },
  });
  const deleteQuote = useDeleteQuoteListingMutation();
  const [form] = Form.useForm();

  const handleEdit = (record: DefaultRecordType) => {
    navigate(`/quote/${record.id}`);
  };
  // const handleDelete = async (record: DefaultRecordType) => {
  //   try {
  //     const { data } = await deleteQuote[0]({ variables: { id: record.id } });
  //     // console.log(data);
  //     if (data?.deleteQuoteListing) {
  //       refetch();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Submitted Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: DefaultRecordType) => (
        <Space size="middle">
          <p style={{ cursor: "pointer" }} onClick={() => handleEdit(record)}>
            Edit
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => showConfirm(record)}>
            Delete
          </p>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setPage(pagination?.current!);
    setVarPage((pagination?.current! - 1) * 10);
    // if (pagination?.current! < page) {
    //   setVarPage(varPage - 10);
    // } else {
    //   setVarPage(quotes?.getQuoteListing?.pageNumber!);
    // }
  };

  const handleSearch = () => {
    setPage(1);
    setVarPage(0);
    setSearchInput(
      form.getFieldValue("search") ? form.getFieldValue("search") : ""
    );
  };

  const handleReset = async () => {
    setPage(1);
    setVarPage(0);
    setSearchInput("");
    form.setFieldsValue({ search: "" });
  };

  const data = quotes?.getQuoteListing?.quotes?.map((quote) => ({
    key: quote.id,
    id: quote.id,
    email: quote.User.email,
    name: quote.User.full_name,
    date: dayjs(new Date(parseInt(quote.createdAt))).format("MM-DD-YYYY"),
    status: quote.isSubmitted ? "QUOTE PRICING SENT" : "WAITING FOR REVIEW",
  }));

  const showConfirm = async (record: DefaultRecordType) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <p>Are you sure to delete quote?</p>,
      async onOk() {
        try {
          const { data } = await deleteQuote[0]({
            variables: { id: record.id },
          });
          console.log(data);
          if (data?.deleteQuoteListing) {
            refetch();
            successModal(data.deleteQuoteListing);
          }
        } catch (error) {
          errorModal();
        }
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const successModal = (content: string) => {
    Modal.info({
      content: content,
    });
  };

  const errorModal = () => {
    Modal.error({
      title: "Error!",
      content: "Error deleting Quote",
    });
  };

  return (
    <CustomLayout defaultSelectedKey="1">
      <Card style={{ margin: 50 }}>
        <StyledDiv>
          <Title level={5}>Search Table</Title>
          <InputWrapper>
            <Form form={form}>
              <Form.Item label="" name="search">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  type={"text"}
                />
              </Form.Item>
            </Form>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button type="default" onClick={handleReset}>
              Reset
            </Button>
          </InputWrapper>
        </StyledDiv>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            current: page,
            pageSize: 10,
            total: quotes?.getQuoteListing?.quoteCount,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </CustomLayout>
  );
};

export default Quotes;
