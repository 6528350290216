import { useState } from "react";
import {
  Typography,
  Table,
  TablePaginationConfig,
  Card,
  Input,
  Button,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FilterValue } from "antd/lib/table/interface";

import { useGetUserListingQuery } from "../hooks/graphql/graphql";
import CustomLayout from "../components/CustomLayout";
const { Title } = Typography;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

const Users = () => {
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [varPage, setVarPage] = useState(0);
  const { data: users, loading } = useGetUserListingQuery({
    variables: {
      searchKeyword: searchInput.length > 0 ? searchInput : "",
      pageNumber: varPage,
    },
  });
  const [form] = Form.useForm();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setPage(pagination?.current!);
    setVarPage((pagination?.current! - 1) * 10);
  };

  const handleSearch = async () => {
    setPage(1);
    setVarPage(0);
    setSearchInput(
      form.getFieldValue("search") ? form.getFieldValue("search") : ""
    );
  };
  const handleReset = async () => {
    setPage(1);
    setVarPage(0);
    setSearchInput("");
    form.setFieldsValue({ search: "" });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone no",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  const data = users?.getUserListing.users?.map((user) => ({
    key: user.id,
    id: user.id,
    email: user.email,
    phone_number: user.phone_number,
    full_name: user.full_name,
  }));

  return (
    <CustomLayout defaultSelectedKey="2">
      <Card style={{ margin: 50 }}>
        <StyledDiv>
          <Title level={5}>Users Table</Title>
          <InputWrapper>
            <Form form={form}>
              <Form.Item label="" name="search">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  type={"text"}
                />
              </Form.Item>
            </Form>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button type="default" onClick={handleReset}>
              Reset
            </Button>
          </InputWrapper>
        </StyledDiv>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            current: page,
            pageSize: 10,
            total: users?.getUserListing.userCount,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </CustomLayout>
  );
};

export default Users;
