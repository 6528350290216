import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import PubSub from "pubsub-js";
import axios from "axios";
import { REFRESH_TOKEN_URL } from "../config";

const AUTH_TOKEN_KEY = "nob-auth-token";
export const SharedStateAuthKey = "@auth";
let authToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";

export const setAuthToken = (token: string = "") => {
  PubSub.publish(SharedStateAuthKey, token.length > 0);

  localStorage.setItem(AUTH_TOKEN_KEY, token);
  authToken = token;
};

export const getAuthToken = () => authToken;

export const hasAuthToken = () => authToken.length > 0;

export const isAuthTokenExpired = () => {
  if (!hasAuthToken()) {
    return true;
  }
  try {
    let token = jwtDecode<{
      exp: number;
    }>(getAuthToken());
    if (token.exp < dayjs().unix()) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return true;
  }
};

export const refreshAuthToken = async () => {
  let response = await axios.get<{
    status: boolean;
    msg: string;
    token: string;
  }>(REFRESH_TOKEN_URL, {
    withCredentials: true,
  });
  // console.log(response);
  if (response.data.status) {
    setAuthToken(response.data.token);
  } else {
    setAuthToken("");
  }
  return response.data;
};

export const removeAuthToken = () => {
  setAuthToken("");
  PubSub.publish(SharedStateAuthKey, false);
};
