import { FC } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AuthRoute: FC = ({ children }) => {
  const auth = useAuth();

  return <>{auth ? children : <Navigate replace to="/login" />}</>;
};

export const NotAllowedToAuthRoute: FC<{ to?: string }> = ({
  to = "/",
  children,
}) => {
  const auth = useAuth();

  return <>{!auth ? children : <Navigate replace to={to} />}</>;
};

export default AuthRoute;
