import { useEffect, useState, useCallback } from "react";
import {
  Card,
  Form,
  Spin,
  Typography,
  Input,
  Button,
  Alert,
  Modal,
} from "antd";

import {
  useGetBasePriceQuery,
  useUpdateBasePriceMutation,
} from "../hooks/graphql/graphql";
import CustomLayout from "../components/CustomLayout";

const { Title } = Typography;

const BasePrice = () => {
  const [submitting, setSubmitting] = useState(false);
  const { loading, data, error } = useGetBasePriceQuery();
  const [form] = Form.useForm();
  const updateBasePrice = useUpdateBasePriceMutation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        portaPottyConstruction: data?.getBasePrice.Porta_potty_Construction,
        portaPottyEvents: data?.getBasePrice.Porta_potty_Events,
        trailersConstruction:
          data?.getBasePrice.Restroom_trailers_Constructions,
        trailersEvents: data?.getBasePrice.Restroom_trailers_Events,
        stationConstruction: data?.getBasePrice.Handwash_stations_Construction,
        stationEvents: data?.getBasePrice.Handwash_stations_Events,
        sanitiserStationEvents:
          data?.getBasePrice.Handsanitiser_stations_Events,
        afterHour: data?.getBasePrice.After_hour_and_Weekend_delivery_charges,
        distanceDelivery:
          data?.getBasePrice.Distance_charges_Long_range_delivery_and_Pickup,
        distanceAdditional:
          data?.getBasePrice.Distance_charges_For_additional_services,
        additionalPortaPotty:
          data?.getBasePrice.Additional_services_Porta_potty,
        additionalHandwash:
          data?.getBasePrice.Additional_services_Handwash_station,
        additionalHandsanitiser:
          data?.getBasePrice.Additional_services_Handsanitiser_station,
        afterHourPortaPotty: data?.getBasePrice.Afterhour_services_Porta_potty,
        afterHourHandwash:
          data?.getBasePrice.Afterhour_services_Handwash_station,
        afterHourHandsanitiser:
          data?.getBasePrice.Afterhour_services_Handsanitiser_station,
        likeNewPortaPotty: data?.getBasePrice.Like_new_package_Porta_potty,
        likeNewHandwash: data?.getBasePrice.Like_new_package_Handwash_station,
        vehicleAccessibility: data?.getBasePrice.Vehicle_accessibility_charges,
        vipAccommodation: data?.getBasePrice.VIP_accommodation_charges,
      });
    }
  }, [data, form]);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        setSubmitting(true);
        const { data } = await updateBasePrice[0]({
          variables: {
            Porta_potty_Construction: parseFloat(values.portaPottyConstruction),
            Porta_potty_Events: parseFloat(values.portaPottyEvents),
            Restroom_trailers_Constructions: parseFloat(
              values.trailersConstruction
            ),
            Restroom_trailers_Events: parseFloat(values.trailersEvents),
            Handwash_stations_Construction: parseFloat(
              values.stationConstruction
            ),
            Handwash_stations_Events: parseFloat(values.stationEvents),
            Handsanitiser_stations_Events: parseFloat(
              values.sanitiserStationEvents
            ),
            After_hour_and_Weekend_delivery_charges: parseFloat(
              values.afterHour
            ),
            Distance_charges_Long_range_delivery_and_Pickup: parseFloat(
              values.distanceDelivery
            ),
            Distance_charges_For_additional_services: parseFloat(
              values.distanceAdditional
            ),
            Additional_services_Porta_potty: parseFloat(
              values.additionalPortaPotty
            ),
            Additional_services_Handwash_station: parseFloat(
              values.additionalHandwash
            ),
            Additional_services_Handsanitiser_station: parseFloat(
              values.additionalHandsanitiser
            ),
            Afterhour_services_Porta_potty: parseFloat(
              values.afterHourPortaPotty
            ),
            Afterhour_services_Handwash_station: parseFloat(
              values.afterHourHandwash
            ),
            Afterhour_services_Handsanitiser_station: parseFloat(
              values.afterHourHandsanitiser
            ),
            Like_new_package_Porta_potty: parseFloat(values.likeNewPortaPotty),
            Like_new_package_Handwash_station: parseFloat(
              values.likeNewHandwash
            ),
            Vehicle_accessibility_charges: parseFloat(
              values.vehicleAccessibility
            ),
            VIP_accommodation_charges: parseFloat(values.vipAccommodation),
          },
        });
        if (data?.updateBasePrice.id) {
          setSubmitting(false);
          successModal();
        }
      } catch (error) {
        console.log(error);
        setSubmitting(false);
      }
    },
    [updateBasePrice]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const successModal = () => {
    Modal.success({
      title: "Success!",
      content: "Base prices has been updated",
    });
  };

  return (
    <CustomLayout defaultSelectedKey="4">
      <div style={{ margin: 50 }}>
        {loading ? <Spin /> : null}
        {error ? <Alert message={error.message} type="error" /> : null}
        {!loading && !error ? (
          <Card>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div>
                <Title level={5}>Porta potty</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Construction"
                      name="portaPottyConstruction"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Events"
                      name="portaPottyEvents"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Restroom trailers</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Construction"
                      name="trailersConstruction"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Events"
                      name="trailersEvents"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Handwash stations</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Construction"
                      name="stationConstruction"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Events"
                      name="stationEvents"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Handsanitiser stations</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Events"
                      name="sanitiserStationEvents"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <Title level={5}>
                <Form.Item
                  label="After hour and Weekend delivery charges"
                  name="afterHour"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type={"number"} />
                </Form.Item>
              </Title>
              <div>
                <Title level={5}>Distance charges</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Long range delivery and Pickup"
                      name="distanceDelivery"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="For additional services"
                      name="distanceAdditional"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Additional services</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Porta potty"
                      name="additionalPortaPotty"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Handwash station"
                      name="additionalHandwash"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Handsanitiser station"
                      name="additionalHandsanitiser"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Afterhour services</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Porta potty"
                      name="afterHourPortaPotty"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Handwash station"
                      name="afterHourHandwash"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Handsanitiser station"
                      name="afterHourHandsanitiser"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <div>
                <Title level={5}>Like new package</Title>
                <ol>
                  <li>
                    <Form.Item
                      label="Porta potty"
                      name="likeNewPortaPotty"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                  <li>
                    <Form.Item
                      label="Handwash station"
                      name="likeNewHandwash"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type={"number"} />
                    </Form.Item>
                  </li>
                </ol>
              </div>
              <Title level={5}>
                <Form.Item
                  label="Vehicle accessibility charges"
                  name="vehicleAccessibility"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type={"number"} />
                </Form.Item>
              </Title>
              <Title level={5}>
                <Form.Item
                  label="VIP accommodation charges"
                  name="vipAccommodation"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type={"number"} />
                </Form.Item>
              </Title>

              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : null}
      </div>
    </CustomLayout>
  );
};

export default BasePrice;
