const DEV_ENDPOINT_URL =
  "https://devapi.numberonebest.com/api/admin";
const PROD_ENDPOINT_URL =
  "https://api.numberonebest.com/api/admin";
const ENDPOINT_URL =
  window.location.host.includes("devadmin") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("127.0.0.1")
    ? DEV_ENDPOINT_URL
    : PROD_ENDPOINT_URL;
export const GQL_URL = ENDPOINT_URL + "/graphql";
export const REFRESH_TOKEN_URL =
  ENDPOINT_URL + "/refreshToken";
