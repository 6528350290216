import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GQL_URL } from "./config";
import {
  getAuthToken,
  hasAuthToken,
  isAuthTokenExpired,
  refreshAuthToken,
} from "./services/authToken";

const httpLink = new HttpLink({
  uri: GQL_URL,
  credentials: "include",
});

const AddToken = new ApolloLink((operation, forward) => {
  if (hasAuthToken()) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }));
  } else {
    console.log("token not found");
  }
  return forward(operation);
});

const RefreshToken = setContext((req) => {
  return new Promise((resolve, reject) => {
    console.log(req);
    if (!hasAuthToken()) {
      resolve(null);
      return;
    }
    if (isAuthTokenExpired()) {
      console.log("TOKEN EXPIRED ⛔️");
      refreshAuthToken()
        .then(() => {
          resolve(null);
        })
        .catch((err: any) => {
          reject(err);
        });
    } else {
      resolve(null);
    }
  });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([RefreshToken, AddToken, httpLink]),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
