import styled from "styled-components";
import { Form, Input, Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../assets/logo.png";
import { useForgotPasswordMutation } from "../hooks/graphql/graphql";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const StyledContainer = styled.div`
  margin-top: 25px;
  text-align: left;
  width: 25rem;
  padding: 38px 30px 25px;
  border-radius: 2px;
  border-top: 2px solid #fc5000;
  box-shadow: rgb(227 233 243) 0px 2px 4px 0px;
`;
const StyledImg = styled.img`
  margin-top: 76px;
`;

const Otp = () => {
  const [form] = Form.useForm();
  const params = useParams<{ email: string }>();
  const navigate = useNavigate();
  const forgotPassword = useForgotPasswordMutation();

  const onFinish = (values: any) => {
    navigate(`/reset-password/${params.email}/${values.otp}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleResend = () => {
    forgotPassword[0]({
      variables: { email: params.email! },
    });
  };

  return (
    <StyledWrapper>
      <div>
        <StyledImg src={logo} alt="logo" />
        <StyledContainer>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Enter the OTP"
              name="otp"
              rules={[
                { required: true, message: "Please input your OTP!" },
                { min: 4, message: "Minimum 4 digits should be there" },
                { max: 4, message: "Maximum 4 digits should be there" },
              ]}
            >
              <Input type="number" minLength={4} maxLength={4} />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Confirm
              </Button>
            </Form.Item>
          </Form>
          <Typography.Text>
            Did not receive OTP?{" "}
            <Typography.Text strong onClick={handleResend}>
              Resend
            </Typography.Text>
          </Typography.Text>
        </StyledContainer>
      </div>
    </StyledWrapper>
  );
};

export default Otp;
