import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AddressResponse = {
  __typename?: 'AddressResponse';
  address_1: Scalars['String'];
  address_2: Scalars['String'];
  city_town: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AdminCommonResponse = {
  __typename?: 'AdminCommonResponse';
  message: Scalars['String'];
  status: Scalars['Boolean'];
};

export type AdminGetQuoteDetailsResponse = {
  __typename?: 'AdminGetQuoteDetailsResponse';
  ADA_charges?: Maybe<Scalars['Float']>;
  User: AdminUserQuoteResponse;
  UserOrder: Array<UserOrder>;
  _count?: Maybe<UserQuoteCount>;
  additionalServices?: Maybe<Scalars['Boolean']>;
  additional_services_charges?: Maybe<Scalars['Float']>;
  afterHours?: Maybe<Scalars['Boolean']>;
  after_hour_services_charges?: Maybe<Scalars['Float']>;
  construction_highRise: Scalars['Boolean'];
  construction_type: ConstructionType;
  createdAt?: Maybe<Scalars['DateTime']>;
  delivery_charges?: Maybe<Scalars['Float']>;
  delivery_date?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  distanceInMiles: Scalars['Float'];
  distance_charges?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['DateTime']>;
  event_Type: Event_Type;
  event_value?: Maybe<EventValue>;
  event_vipAccommodation: Scalars['Boolean'];
  from_delivery_time?: Maybe<Scalars['DateTime']>;
  handwashStation?: Maybe<Scalars['Boolean']>;
  handwash_sanitizers_charges?: Maybe<Scalars['Float']>;
  handwash_sinks_charges?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMultiHandSink?: Maybe<Scalars['Boolean']>;
  job_Site?: Maybe<JobSite>;
  likeNewPackage?: Maybe<Scalars['Boolean']>;
  like_new_package_charges?: Maybe<Scalars['Float']>;
  no_of_additional_services?: Maybe<Scalars['Int']>;
  no_of_attendees?: Maybe<Scalars['String']>;
  porta_porty_charges?: Maybe<Scalars['Float']>;
  product_ADA?: Maybe<Scalars['Int']>;
  product_handwashSanitizers?: Maybe<Scalars['Int']>;
  product_handwashSinks?: Maybe<Scalars['Int']>;
  product_porta_porty?: Maybe<Scalars['Int']>;
  service_Sector?: Maybe<ServiceSector>;
  startDate?: Maybe<Scalars['DateTime']>;
  startHour?: Maybe<Scalars['DateTime']>;
  stationType?: Maybe<HandwashStationType>;
  subtotal?: Maybe<Scalars['Float']>;
  to_delivery_time?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleAccessible?: Maybe<Scalars['Boolean']>;
  vehicle_accessible_charges?: Maybe<Scalars['Float']>;
  weekendServices?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AdminLoginResponse = {
  __typename?: 'AdminLoginResponse';
  message: Scalars['String'];
  status: Scalars['Boolean'];
  token: Scalars['String'];
};

export type AdminOrderDetailsResponse = {
  __typename?: 'AdminOrderDetailsResponse';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  customerAddress?: Maybe<AddressResponse>;
  id: Scalars['String'];
  status: Scalars['String'];
  user: AdminUserListingResponse;
  userQuoteId: Scalars['String'];
};

export type AdminOrderListingResponse = {
  __typename?: 'AdminOrderListingResponse';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  user: AdminOrderListingUserResponse;
  userQuoteId: Scalars['String'];
};

export type AdminOrderListingUserResponse = {
  __typename?: 'AdminOrderListingUserResponse';
  full_name: Scalars['String'];
};

export type AdminOrderListingWithPaginationResponse = {
  __typename?: 'AdminOrderListingWithPaginationResponse';
  orders: Array<AdminOrderListingResponse>;
  ordersCount: Scalars['Float'];
  pageNumber: Scalars['Float'];
};

export type AdminProfileResponse = {
  __typename?: 'AdminProfileResponse';
  email: Scalars['String'];
  full_name: Scalars['String'];
};

export type AdminQuoteListingResponse = {
  __typename?: 'AdminQuoteListingResponse';
  User: AdminUserListingResponse;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
};

export type AdminQuoteListingWithPaginationResponse = {
  __typename?: 'AdminQuoteListingWithPaginationResponse';
  pageNumber: Scalars['Float'];
  quoteCount: Scalars['Float'];
  quotes: Array<AdminQuoteListingResponse>;
};

export type AdminSendQuoteResponse = {
  __typename?: 'AdminSendQuoteResponse';
  amount: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
  stripePaymentId: Scalars['String'];
  stripePaymentLink: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
  userQuoteId: Scalars['String'];
};

export type AdminUpdateQuoteDetailsInput = {
  ADA?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  handwashSanitizers?: InputMaybe<Scalars['Float']>;
  handwashSinks?: InputMaybe<Scalars['Float']>;
  porta_porty?: InputMaybe<Scalars['Float']>;
  quotesId: Scalars['String'];
};

export type AdminUserListingResponse = {
  __typename?: 'AdminUserListingResponse';
  email: Scalars['String'];
  full_name: Scalars['String'];
  id: Scalars['String'];
  phone_number: Scalars['String'];
};

export type AdminUserListingWithPaginationResponse = {
  __typename?: 'AdminUserListingWithPaginationResponse';
  pageNumber: Scalars['Float'];
  userCount: Scalars['Float'];
  users: Array<AdminUserListingResponse>;
};

export type AdminUserQuoteResponse = {
  __typename?: 'AdminUserQuoteResponse';
  email: Scalars['String'];
  full_name: Scalars['String'];
  id: Scalars['String'];
  phone_number: Scalars['String'];
  status: Scalars['String'];
  stripeId: Scalars['String'];
  tokenVersion: Scalars['Float'];
};

export type BasePrice = {
  __typename?: 'BasePrice';
  ADA_charges: Scalars['Float'];
  Additional_services_Handsanitiser_station: Scalars['Float'];
  Additional_services_Handwash_station: Scalars['Float'];
  Additional_services_Porta_potty: Scalars['Float'];
  After_hour_and_Weekend_delivery_charges: Scalars['Float'];
  Afterhour_services_Handsanitiser_station: Scalars['Float'];
  Afterhour_services_Handwash_station: Scalars['Float'];
  Afterhour_services_Porta_potty: Scalars['Float'];
  Distance_charges_For_additional_services: Scalars['Float'];
  Distance_charges_Long_range_delivery_and_Pickup: Scalars['Float'];
  Handsanitiser_stations_Events: Scalars['Float'];
  Handwash_stations_Construction: Scalars['Float'];
  Handwash_stations_Events: Scalars['Float'];
  Like_new_package_Handwash_station: Scalars['Float'];
  Like_new_package_Porta_potty: Scalars['Float'];
  Porta_potty_Construction: Scalars['Float'];
  Porta_potty_Events: Scalars['Float'];
  Restroom_trailers_Constructions: Scalars['Float'];
  Restroom_trailers_Events: Scalars['Float'];
  VIP_accommodation_charges: Scalars['Float'];
  Vehicle_accessibility_charges: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BasePriceUpdateInput = {
  ADA_charges?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Additional_services_Handsanitiser_station?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Additional_services_Handwash_station?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Additional_services_Porta_potty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  After_hour_and_Weekend_delivery_charges?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Afterhour_services_Handsanitiser_station?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Afterhour_services_Handwash_station?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Afterhour_services_Porta_potty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Distance_charges_For_additional_services?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Distance_charges_Long_range_delivery_and_Pickup?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Handsanitiser_stations_Events?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Handwash_stations_Construction?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Handwash_stations_Events?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Like_new_package_Handwash_station?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Like_new_package_Porta_potty?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Porta_potty_Construction?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Porta_potty_Events?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Restroom_trailers_Constructions?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Restroom_trailers_Events?: InputMaybe<FloatFieldUpdateOperationsInput>;
  VIP_accommodation_charges?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Vehicle_accessibility_charges?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export enum ConstructionType {
  Commercial = 'COMMERCIAL',
  Development = 'DEVELOPMENT',
  Residential = 'RESIDENTIAL'
}

export enum EventValue {
  Birthday = 'BIRTHDAY',
  Blockparty = 'BLOCKPARTY',
  Community = 'COMMUNITY',
  Convention = 'CONVENTION',
  Festival = 'FESTIVAL',
  Healthcare = 'HEALTHCARE',
  Music = 'MUSIC',
  Other = 'OTHER',
  Parade = 'PARADE',
  Party = 'PARTY',
  Sporting = 'SPORTING',
  Wedding = 'WEDDING'
}

export enum Event_Type {
  Party = 'PARTY',
  Public = 'PUBLIC',
  Ticketed = 'TICKETED'
}

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export enum HandwashStationType {
  HandSanatizerStand = 'Hand_Sanatizer_Stand',
  HandwashSinks = 'Handwash_Sinks'
}

export enum JobSite {
  Construction = 'CONSTRUCTION',
  Event = 'EVENT',
  Longterm = 'LONGTERM'
}

export type Mutation = {
  __typename?: 'Mutation';
  createSendQuote: AdminSendQuoteResponse;
  deleteQuoteListing: Scalars['String'];
  forgotPasswordWithEmailOTP: AdminCommonResponse;
  login: AdminLoginResponse;
  resetPasswordWithEmailOTP: AdminCommonResponse;
  updateBasePrice: BasePrice;
  updateQuoteDetails: Scalars['String'];
};


export type MutationCreateSendQuoteArgs = {
  quoteId: Scalars['String'];
};


export type MutationDeleteQuoteListingArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordWithEmailOtpArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordWithEmailOtpArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationUpdateBasePriceArgs = {
  data: BasePriceUpdateInput;
};


export type MutationUpdateQuoteDetailsArgs = {
  data: AdminUpdateQuoteDetailsInput;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type Query = {
  __typename?: 'Query';
  getBasePrice: BasePrice;
  getOrderDetails: AdminOrderDetailsResponse;
  getOrderListing: AdminOrderListingWithPaginationResponse;
  getQuoteDetails: AdminGetQuoteDetailsResponse;
  getQuoteListing?: Maybe<AdminQuoteListingWithPaginationResponse>;
  getUserListing: AdminUserListingWithPaginationResponse;
  me: AdminProfileResponse;
  test: Scalars['String'];
};


export type QueryGetOrderDetailsArgs = {
  orderId: Scalars['String'];
};


export type QueryGetOrderListingArgs = {
  pageNumber: Scalars['Float'];
  searchKeyword?: InputMaybe<Scalars['String']>;
};


export type QueryGetQuoteDetailsArgs = {
  quoteId: Scalars['String'];
};


export type QueryGetQuoteListingArgs = {
  pageNumber: Scalars['Float'];
  searchKeyword?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserListingArgs = {
  pageNumber: Scalars['Float'];
  searchKeyword?: InputMaybe<Scalars['String']>;
};

export enum ServiceSector {
  Business = 'BUSINESS',
  Government = 'GOVERNMENT',
  Personal = 'PERSONAL'
}

export type UserOrder = {
  __typename?: 'UserOrder';
  addressId?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  cardConnectPaymentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isQuoteSent: Scalars['Boolean'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userQuoteId: Scalars['String'];
};

export type UserQuoteCount = {
  __typename?: 'UserQuoteCount';
  UserOrder: Scalars['Int'];
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPasswordWithEmailOTP: { __typename?: 'AdminCommonResponse', message: string, status: boolean } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AdminLoginResponse', token: string, message: string, status: boolean } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  otp: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPasswordWithEmailOTP: { __typename?: 'AdminCommonResponse', message: string, status: boolean } };

export type CreateSendQuoteMutationVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type CreateSendQuoteMutation = { __typename?: 'Mutation', createSendQuote: { __typename?: 'AdminSendQuoteResponse', id: string } };

export type DeleteQuoteListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuoteListingMutation = { __typename?: 'Mutation', deleteQuoteListing: string };

export type GetBasePriceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBasePriceQuery = { __typename?: 'Query', getBasePrice: { __typename?: 'BasePrice', Porta_potty_Construction: number, Porta_potty_Events: number, Restroom_trailers_Constructions: number, Restroom_trailers_Events: number, Handwash_stations_Construction: number, Handwash_stations_Events: number, Handsanitiser_stations_Events: number, After_hour_and_Weekend_delivery_charges: number, Distance_charges_Long_range_delivery_and_Pickup: number, Distance_charges_For_additional_services: number, Additional_services_Porta_potty: number, Additional_services_Handwash_station: number, Additional_services_Handsanitiser_station: number, Afterhour_services_Porta_potty: number, Afterhour_services_Handwash_station: number, Afterhour_services_Handsanitiser_station: number, Like_new_package_Porta_potty: number, Like_new_package_Handwash_station: number, Vehicle_accessibility_charges: number, VIP_accommodation_charges: number } };

export type GetOrderDetailsQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderDetailsQuery = { __typename?: 'Query', getOrderDetails: { __typename?: 'AdminOrderDetailsResponse', id: string, userQuoteId: string, createdAt: any, amount: number, status: string, user: { __typename?: 'AdminUserListingResponse', full_name: string, email: string, phone_number: string }, customerAddress?: { __typename?: 'AddressResponse', address_1: string, address_2: string, zipcode: string, city_town: string } | null } };

export type GetOrderListingQueryVariables = Exact<{
  searchKeyword: Scalars['String'];
  pageNumber: Scalars['Float'];
}>;


export type GetOrderListingQuery = { __typename?: 'Query', getOrderListing: { __typename?: 'AdminOrderListingWithPaginationResponse', pageNumber: number, ordersCount: number, orders: Array<{ __typename?: 'AdminOrderListingResponse', userQuoteId: string, id: string, createdAt: string, user: { __typename?: 'AdminOrderListingUserResponse', full_name: string } }> } };

export type GetQuoteDetailsQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type GetQuoteDetailsQuery = { __typename?: 'Query', getQuoteDetails: { __typename?: 'AdminGetQuoteDetailsResponse', id: string, createdAt?: any | null, service_Sector?: ServiceSector | null, job_Site?: JobSite | null, construction_type: ConstructionType, construction_highRise: boolean, event_Type: Event_Type, event_value?: EventValue | null, event_vipAccommodation: boolean, vehicleAccessible?: boolean | null, startDate?: any | null, endDate?: any | null, startHour?: any | null, endHour?: any | null, no_of_attendees?: string | null, zipcode?: string | null, delivery_date?: any | null, from_delivery_time?: any | null, to_delivery_time?: any | null, afterHours?: boolean | null, weekendServices?: boolean | null, additionalServices?: boolean | null, no_of_additional_services?: number | null, likeNewPackage?: boolean | null, handwashStation?: boolean | null, stationType?: HandwashStationType | null, vehicle_accessible_charges?: number | null, distance_charges?: number | null, delivery_charges?: number | null, after_hour_services_charges?: number | null, additional_services_charges?: number | null, like_new_package_charges?: number | null, porta_porty_charges?: number | null, ADA_charges?: number | null, handwash_sinks_charges?: number | null, handwash_sanitizers_charges?: number | null, subtotal?: number | null, total?: number | null, discount?: number | null, product_porta_porty?: number | null, product_ADA?: number | null, product_handwashSinks?: number | null, product_handwashSanitizers?: number | null, distanceInMiles: number, isMultiHandSink?: boolean | null, User: { __typename?: 'AdminUserQuoteResponse', full_name: string, email: string, phone_number: string }, UserOrder: Array<{ __typename?: 'UserOrder', id: string, userQuoteId: string, status: OrderStatus }> } };

export type GetQuoteListingQueryVariables = Exact<{
  searchKeyword: Scalars['String'];
  pageNumber: Scalars['Float'];
}>;


export type GetQuoteListingQuery = { __typename?: 'Query', getQuoteListing?: { __typename?: 'AdminQuoteListingWithPaginationResponse', pageNumber: number, quoteCount: number, quotes: Array<{ __typename?: 'AdminQuoteListingResponse', id: string, isSubmitted: boolean, createdAt: string, User: { __typename?: 'AdminUserListingResponse', full_name: string, email: string } }> } | null };

export type GetUserListingQueryVariables = Exact<{
  searchKeyword: Scalars['String'];
  pageNumber: Scalars['Float'];
}>;


export type GetUserListingQuery = { __typename?: 'Query', getUserListing: { __typename?: 'AdminUserListingWithPaginationResponse', pageNumber: number, userCount: number, users: Array<{ __typename?: 'AdminUserListingResponse', id: string, full_name: string, email: string, phone_number: string }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'AdminProfileResponse', full_name: string, email: string } };

export type UpdateBasePriceMutationVariables = Exact<{
  Porta_potty_Construction: Scalars['Float'];
  Porta_potty_Events: Scalars['Float'];
  Restroom_trailers_Constructions: Scalars['Float'];
  Restroom_trailers_Events: Scalars['Float'];
  Handwash_stations_Construction: Scalars['Float'];
  Handwash_stations_Events: Scalars['Float'];
  Handsanitiser_stations_Events: Scalars['Float'];
  After_hour_and_Weekend_delivery_charges: Scalars['Float'];
  Distance_charges_Long_range_delivery_and_Pickup: Scalars['Float'];
  Distance_charges_For_additional_services: Scalars['Float'];
  Additional_services_Porta_potty: Scalars['Float'];
  Additional_services_Handwash_station: Scalars['Float'];
  Additional_services_Handsanitiser_station: Scalars['Float'];
  Afterhour_services_Porta_potty: Scalars['Float'];
  Afterhour_services_Handwash_station: Scalars['Float'];
  Afterhour_services_Handsanitiser_station: Scalars['Float'];
  Like_new_package_Porta_potty: Scalars['Float'];
  Like_new_package_Handwash_station: Scalars['Float'];
  Vehicle_accessibility_charges: Scalars['Float'];
  VIP_accommodation_charges: Scalars['Float'];
}>;


export type UpdateBasePriceMutation = { __typename?: 'Mutation', updateBasePrice: { __typename?: 'BasePrice', id: string } };

export type UpdateQuoteDetailsMutationVariables = Exact<{
  porta_porty: Scalars['Float'];
  ADA: Scalars['Float'];
  handwashSinks: Scalars['Float'];
  handwashSanitizers: Scalars['Float'];
  discount: Scalars['Float'];
  quotesId: Scalars['String'];
}>;


export type UpdateQuoteDetailsMutation = { __typename?: 'Mutation', updateQuoteDetails: string };


export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPasswordWithEmailOTP(email: $email) {
    message
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    message
    status
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $otp: String!, $newPassword: String!) {
  resetPasswordWithEmailOTP(newPassword: $newPassword, otp: $otp, email: $email) {
    message
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      otp: // value for 'otp'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateSendQuoteDocument = gql`
    mutation createSendQuote($quoteId: String!) {
  createSendQuote(quoteId: $quoteId) {
    id
  }
}
    `;
export type CreateSendQuoteMutationFn = Apollo.MutationFunction<CreateSendQuoteMutation, CreateSendQuoteMutationVariables>;

/**
 * __useCreateSendQuoteMutation__
 *
 * To run a mutation, you first call `useCreateSendQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSendQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSendQuoteMutation, { data, loading, error }] = useCreateSendQuoteMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useCreateSendQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSendQuoteMutation, CreateSendQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSendQuoteMutation, CreateSendQuoteMutationVariables>(CreateSendQuoteDocument, options);
      }
export type CreateSendQuoteMutationHookResult = ReturnType<typeof useCreateSendQuoteMutation>;
export type CreateSendQuoteMutationResult = Apollo.MutationResult<CreateSendQuoteMutation>;
export type CreateSendQuoteMutationOptions = Apollo.BaseMutationOptions<CreateSendQuoteMutation, CreateSendQuoteMutationVariables>;
export const DeleteQuoteListingDocument = gql`
    mutation deleteQuoteListing($id: String!) {
  deleteQuoteListing(id: $id)
}
    `;
export type DeleteQuoteListingMutationFn = Apollo.MutationFunction<DeleteQuoteListingMutation, DeleteQuoteListingMutationVariables>;

/**
 * __useDeleteQuoteListingMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteListingMutation, { data, loading, error }] = useDeleteQuoteListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuoteListingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteListingMutation, DeleteQuoteListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteListingMutation, DeleteQuoteListingMutationVariables>(DeleteQuoteListingDocument, options);
      }
export type DeleteQuoteListingMutationHookResult = ReturnType<typeof useDeleteQuoteListingMutation>;
export type DeleteQuoteListingMutationResult = Apollo.MutationResult<DeleteQuoteListingMutation>;
export type DeleteQuoteListingMutationOptions = Apollo.BaseMutationOptions<DeleteQuoteListingMutation, DeleteQuoteListingMutationVariables>;
export const GetBasePriceDocument = gql`
    query getBasePrice {
  getBasePrice {
    Porta_potty_Construction
    Porta_potty_Events
    Restroom_trailers_Constructions
    Restroom_trailers_Events
    Handwash_stations_Construction
    Handwash_stations_Events
    Handsanitiser_stations_Events
    After_hour_and_Weekend_delivery_charges
    Distance_charges_Long_range_delivery_and_Pickup
    Distance_charges_For_additional_services
    Additional_services_Porta_potty
    Additional_services_Handwash_station
    Additional_services_Handsanitiser_station
    Afterhour_services_Porta_potty
    Afterhour_services_Handwash_station
    Afterhour_services_Handsanitiser_station
    Like_new_package_Porta_potty
    Like_new_package_Handwash_station
    Vehicle_accessibility_charges
    VIP_accommodation_charges
  }
}
    `;

/**
 * __useGetBasePriceQuery__
 *
 * To run a query within a React component, call `useGetBasePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePriceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasePriceQuery(baseOptions?: Apollo.QueryHookOptions<GetBasePriceQuery, GetBasePriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasePriceQuery, GetBasePriceQueryVariables>(GetBasePriceDocument, options);
      }
export function useGetBasePriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasePriceQuery, GetBasePriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasePriceQuery, GetBasePriceQueryVariables>(GetBasePriceDocument, options);
        }
export type GetBasePriceQueryHookResult = ReturnType<typeof useGetBasePriceQuery>;
export type GetBasePriceLazyQueryHookResult = ReturnType<typeof useGetBasePriceLazyQuery>;
export type GetBasePriceQueryResult = Apollo.QueryResult<GetBasePriceQuery, GetBasePriceQueryVariables>;
export const GetOrderDetailsDocument = gql`
    query getOrderDetails($orderId: String!) {
  getOrderDetails(orderId: $orderId) {
    id
    userQuoteId
    createdAt
    amount
    status
    user {
      full_name
      email
      phone_number
    }
    customerAddress {
      address_1
      address_2
      zipcode
      city_town
    }
  }
}
    `;

/**
 * __useGetOrderDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>(GetOrderDetailsDocument, options);
      }
export function useGetOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>(GetOrderDetailsDocument, options);
        }
export type GetOrderDetailsQueryHookResult = ReturnType<typeof useGetOrderDetailsQuery>;
export type GetOrderDetailsLazyQueryHookResult = ReturnType<typeof useGetOrderDetailsLazyQuery>;
export type GetOrderDetailsQueryResult = Apollo.QueryResult<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>;
export const GetOrderListingDocument = gql`
    query getOrderListing($searchKeyword: String!, $pageNumber: Float!) {
  getOrderListing(searchKeyword: $searchKeyword, pageNumber: $pageNumber) {
    orders {
      userQuoteId
      id
      user {
        full_name
      }
      createdAt
    }
    pageNumber
    ordersCount
  }
}
    `;

/**
 * __useGetOrderListingQuery__
 *
 * To run a query within a React component, call `useGetOrderListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderListingQuery({
 *   variables: {
 *      searchKeyword: // value for 'searchKeyword'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useGetOrderListingQuery(baseOptions: Apollo.QueryHookOptions<GetOrderListingQuery, GetOrderListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderListingQuery, GetOrderListingQueryVariables>(GetOrderListingDocument, options);
      }
export function useGetOrderListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderListingQuery, GetOrderListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderListingQuery, GetOrderListingQueryVariables>(GetOrderListingDocument, options);
        }
export type GetOrderListingQueryHookResult = ReturnType<typeof useGetOrderListingQuery>;
export type GetOrderListingLazyQueryHookResult = ReturnType<typeof useGetOrderListingLazyQuery>;
export type GetOrderListingQueryResult = Apollo.QueryResult<GetOrderListingQuery, GetOrderListingQueryVariables>;
export const GetQuoteDetailsDocument = gql`
    query getQuoteDetails($quoteId: String!) {
  getQuoteDetails(quoteId: $quoteId) {
    id
    User {
      full_name
      email
      phone_number
    }
    createdAt
    UserOrder {
      id
      userQuoteId
      status
    }
    service_Sector
    job_Site
    construction_type
    construction_highRise
    event_Type
    event_value
    event_vipAccommodation
    vehicleAccessible
    startDate
    endDate
    startHour
    endHour
    no_of_attendees
    zipcode
    delivery_date
    from_delivery_time
    to_delivery_time
    afterHours
    weekendServices
    additionalServices
    no_of_additional_services
    likeNewPackage
    handwashStation
    stationType
    vehicle_accessible_charges
    distance_charges
    delivery_charges
    after_hour_services_charges
    additional_services_charges
    like_new_package_charges
    porta_porty_charges
    ADA_charges
    handwash_sinks_charges
    handwash_sanitizers_charges
    subtotal
    total
    discount
    product_porta_porty
    product_ADA
    product_handwashSinks
    product_handwashSanitizers
    distanceInMiles
    isMultiHandSink
  }
}
    `;

/**
 * __useGetQuoteDetailsQuery__
 *
 * To run a query within a React component, call `useGetQuoteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteDetailsQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuoteDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteDetailsQuery, GetQuoteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteDetailsQuery, GetQuoteDetailsQueryVariables>(GetQuoteDetailsDocument, options);
      }
export function useGetQuoteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteDetailsQuery, GetQuoteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteDetailsQuery, GetQuoteDetailsQueryVariables>(GetQuoteDetailsDocument, options);
        }
export type GetQuoteDetailsQueryHookResult = ReturnType<typeof useGetQuoteDetailsQuery>;
export type GetQuoteDetailsLazyQueryHookResult = ReturnType<typeof useGetQuoteDetailsLazyQuery>;
export type GetQuoteDetailsQueryResult = Apollo.QueryResult<GetQuoteDetailsQuery, GetQuoteDetailsQueryVariables>;
export const GetQuoteListingDocument = gql`
    query getQuoteListing($searchKeyword: String!, $pageNumber: Float!) {
  getQuoteListing(searchKeyword: $searchKeyword, pageNumber: $pageNumber) {
    quotes {
      id
      User {
        full_name
        email
      }
      isSubmitted
      createdAt
    }
    pageNumber
    quoteCount
  }
}
    `;

/**
 * __useGetQuoteListingQuery__
 *
 * To run a query within a React component, call `useGetQuoteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteListingQuery({
 *   variables: {
 *      searchKeyword: // value for 'searchKeyword'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useGetQuoteListingQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteListingQuery, GetQuoteListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteListingQuery, GetQuoteListingQueryVariables>(GetQuoteListingDocument, options);
      }
export function useGetQuoteListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteListingQuery, GetQuoteListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteListingQuery, GetQuoteListingQueryVariables>(GetQuoteListingDocument, options);
        }
export type GetQuoteListingQueryHookResult = ReturnType<typeof useGetQuoteListingQuery>;
export type GetQuoteListingLazyQueryHookResult = ReturnType<typeof useGetQuoteListingLazyQuery>;
export type GetQuoteListingQueryResult = Apollo.QueryResult<GetQuoteListingQuery, GetQuoteListingQueryVariables>;
export const GetUserListingDocument = gql`
    query getUserListing($searchKeyword: String!, $pageNumber: Float!) {
  getUserListing(searchKeyword: $searchKeyword, pageNumber: $pageNumber) {
    users {
      id
      full_name
      email
      phone_number
    }
    pageNumber
    userCount
  }
}
    `;

/**
 * __useGetUserListingQuery__
 *
 * To run a query within a React component, call `useGetUserListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListingQuery({
 *   variables: {
 *      searchKeyword: // value for 'searchKeyword'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useGetUserListingQuery(baseOptions: Apollo.QueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, options);
      }
export function useGetUserListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, options);
        }
export type GetUserListingQueryHookResult = ReturnType<typeof useGetUserListingQuery>;
export type GetUserListingLazyQueryHookResult = ReturnType<typeof useGetUserListingLazyQuery>;
export type GetUserListingQueryResult = Apollo.QueryResult<GetUserListingQuery, GetUserListingQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    full_name
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateBasePriceDocument = gql`
    mutation updateBasePrice($Porta_potty_Construction: Float!, $Porta_potty_Events: Float!, $Restroom_trailers_Constructions: Float!, $Restroom_trailers_Events: Float!, $Handwash_stations_Construction: Float!, $Handwash_stations_Events: Float!, $Handsanitiser_stations_Events: Float!, $After_hour_and_Weekend_delivery_charges: Float!, $Distance_charges_Long_range_delivery_and_Pickup: Float!, $Distance_charges_For_additional_services: Float!, $Additional_services_Porta_potty: Float!, $Additional_services_Handwash_station: Float!, $Additional_services_Handsanitiser_station: Float!, $Afterhour_services_Porta_potty: Float!, $Afterhour_services_Handwash_station: Float!, $Afterhour_services_Handsanitiser_station: Float!, $Like_new_package_Porta_potty: Float!, $Like_new_package_Handwash_station: Float!, $Vehicle_accessibility_charges: Float!, $VIP_accommodation_charges: Float!) {
  updateBasePrice(
    data: {Porta_potty_Construction: {set: $Porta_potty_Construction}, Porta_potty_Events: {set: $Porta_potty_Events}, Restroom_trailers_Constructions: {set: $Restroom_trailers_Constructions}, Restroom_trailers_Events: {set: $Restroom_trailers_Events}, Handwash_stations_Construction: {set: $Handwash_stations_Construction}, Handwash_stations_Events: {set: $Handwash_stations_Events}, Handsanitiser_stations_Events: {set: $Handsanitiser_stations_Events}, After_hour_and_Weekend_delivery_charges: {set: $After_hour_and_Weekend_delivery_charges}, Distance_charges_Long_range_delivery_and_Pickup: {set: $Distance_charges_Long_range_delivery_and_Pickup}, Distance_charges_For_additional_services: {set: $Distance_charges_For_additional_services}, Additional_services_Porta_potty: {set: $Additional_services_Porta_potty}, Additional_services_Handwash_station: {set: $Additional_services_Handwash_station}, Additional_services_Handsanitiser_station: {set: $Additional_services_Handsanitiser_station}, Afterhour_services_Porta_potty: {set: $Afterhour_services_Porta_potty}, Afterhour_services_Handwash_station: {set: $Afterhour_services_Handwash_station}, Afterhour_services_Handsanitiser_station: {set: $Afterhour_services_Handsanitiser_station}, Like_new_package_Porta_potty: {set: $Like_new_package_Porta_potty}, Like_new_package_Handwash_station: {set: $Like_new_package_Handwash_station}, Vehicle_accessibility_charges: {set: $Vehicle_accessibility_charges}, VIP_accommodation_charges: {set: $VIP_accommodation_charges}}
  ) {
    id
  }
}
    `;
export type UpdateBasePriceMutationFn = Apollo.MutationFunction<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>;

/**
 * __useUpdateBasePriceMutation__
 *
 * To run a mutation, you first call `useUpdateBasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasePriceMutation, { data, loading, error }] = useUpdateBasePriceMutation({
 *   variables: {
 *      Porta_potty_Construction: // value for 'Porta_potty_Construction'
 *      Porta_potty_Events: // value for 'Porta_potty_Events'
 *      Restroom_trailers_Constructions: // value for 'Restroom_trailers_Constructions'
 *      Restroom_trailers_Events: // value for 'Restroom_trailers_Events'
 *      Handwash_stations_Construction: // value for 'Handwash_stations_Construction'
 *      Handwash_stations_Events: // value for 'Handwash_stations_Events'
 *      Handsanitiser_stations_Events: // value for 'Handsanitiser_stations_Events'
 *      After_hour_and_Weekend_delivery_charges: // value for 'After_hour_and_Weekend_delivery_charges'
 *      Distance_charges_Long_range_delivery_and_Pickup: // value for 'Distance_charges_Long_range_delivery_and_Pickup'
 *      Distance_charges_For_additional_services: // value for 'Distance_charges_For_additional_services'
 *      Additional_services_Porta_potty: // value for 'Additional_services_Porta_potty'
 *      Additional_services_Handwash_station: // value for 'Additional_services_Handwash_station'
 *      Additional_services_Handsanitiser_station: // value for 'Additional_services_Handsanitiser_station'
 *      Afterhour_services_Porta_potty: // value for 'Afterhour_services_Porta_potty'
 *      Afterhour_services_Handwash_station: // value for 'Afterhour_services_Handwash_station'
 *      Afterhour_services_Handsanitiser_station: // value for 'Afterhour_services_Handsanitiser_station'
 *      Like_new_package_Porta_potty: // value for 'Like_new_package_Porta_potty'
 *      Like_new_package_Handwash_station: // value for 'Like_new_package_Handwash_station'
 *      Vehicle_accessibility_charges: // value for 'Vehicle_accessibility_charges'
 *      VIP_accommodation_charges: // value for 'VIP_accommodation_charges'
 *   },
 * });
 */
export function useUpdateBasePriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>(UpdateBasePriceDocument, options);
      }
export type UpdateBasePriceMutationHookResult = ReturnType<typeof useUpdateBasePriceMutation>;
export type UpdateBasePriceMutationResult = Apollo.MutationResult<UpdateBasePriceMutation>;
export type UpdateBasePriceMutationOptions = Apollo.BaseMutationOptions<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>;
export const UpdateQuoteDetailsDocument = gql`
    mutation updateQuoteDetails($porta_porty: Float!, $ADA: Float!, $handwashSinks: Float!, $handwashSanitizers: Float!, $discount: Float!, $quotesId: String!) {
  updateQuoteDetails(
    data: {porta_porty: $porta_porty, ADA: $ADA, handwashSinks: $handwashSinks, handwashSanitizers: $handwashSanitizers, discount: $discount, quotesId: $quotesId}
  )
}
    `;
export type UpdateQuoteDetailsMutationFn = Apollo.MutationFunction<UpdateQuoteDetailsMutation, UpdateQuoteDetailsMutationVariables>;

/**
 * __useUpdateQuoteDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteDetailsMutation, { data, loading, error }] = useUpdateQuoteDetailsMutation({
 *   variables: {
 *      porta_porty: // value for 'porta_porty'
 *      ADA: // value for 'ADA'
 *      handwashSinks: // value for 'handwashSinks'
 *      handwashSanitizers: // value for 'handwashSanitizers'
 *      discount: // value for 'discount'
 *      quotesId: // value for 'quotesId'
 *   },
 * });
 */
export function useUpdateQuoteDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteDetailsMutation, UpdateQuoteDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteDetailsMutation, UpdateQuoteDetailsMutationVariables>(UpdateQuoteDetailsDocument, options);
      }
export type UpdateQuoteDetailsMutationHookResult = ReturnType<typeof useUpdateQuoteDetailsMutation>;
export type UpdateQuoteDetailsMutationResult = Apollo.MutationResult<UpdateQuoteDetailsMutation>;
export type UpdateQuoteDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteDetailsMutation, UpdateQuoteDetailsMutationVariables>;