import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.less";
import AuthRoute, { NotAllowedToAuthRoute } from "./components/AuthRoute";
import BasePrice from "./pages/BasePrice";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Order from "./pages/Order";
import Orders from "./pages/Orders";
import Otp from "./pages/Otp";
import Quote from "./pages/Quote";
import Quotes from "./pages/Quotes";
import ResetPassword from "./pages/ResetPassword";
import Users from "./pages/Users";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Quotes />
            </AuthRoute>
          }
        />
        <Route path="/quote/:id" element={<Quote />} />
        <Route
          path="/login"
          element={
            <NotAllowedToAuthRoute>
              <Login />
            </NotAllowedToAuthRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <NotAllowedToAuthRoute>
              <ForgotPassword />
            </NotAllowedToAuthRoute>
          }
        />
        <Route
          path="/otp/:email"
          element={
            <NotAllowedToAuthRoute>
              <Otp />
            </NotAllowedToAuthRoute>
          }
        />
        <Route
          path="/reset-password/:email/:otp"
          element={
            <NotAllowedToAuthRoute>
              <ResetPassword />
            </NotAllowedToAuthRoute>
          }
        />
        <Route
          path="/users"
          element={
            <AuthRoute>
              <Users />
            </AuthRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <AuthRoute>
              <Orders />
            </AuthRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <AuthRoute>
              <Order />
            </AuthRoute>
          }
        />
        <Route
          path="/base-price"
          element={
            <AuthRoute>
              <BasePrice />
            </AuthRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <AuthRoute>
              <Logout />
            </AuthRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
