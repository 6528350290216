import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeAuthToken } from "../services/authToken";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    removeAuthToken();
    navigate("/login");
  });
  return null;
};

export default Logout;
