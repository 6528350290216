import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Button,
  Modal,
  Spin,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  JobSite,
  useCreateSendQuoteMutation,
  useGetBasePriceQuery,
  useGetQuoteDetailsQuery,
  useUpdateQuoteDetailsMutation,
} from "../hooks/graphql/graphql";
import CustomLayout from "../components/CustomLayout";
const { Title } = Typography;
const { confirm } = Modal;
dayjs.extend(utc);

const Table = styled.table`
  width: 100%;
  thead th {
    background-color: #fafafa;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #fafafa;
  }
`;

const Quote = () => {
  const [adaQty, setAdaQty] = useState<number>(0);
  const [sinksQty, setSinksQty] = useState<number>(0);
  const [portaPottyQty, setPortaPottyQty] =
    useState<number>(0);
  const [handwashStationQty, setHandwashStationQty] =
    useState<number>(0);
  const [discountPer, setDiscountPer] = useState<number>(0);
  // charges state
  const [portaPottyCharges, setPortaPottyCharges] =
    useState<number>(0);
  const [adaCharges, setAdaCharges] = useState<number>(0);
  const [sinksCharges, setSinksCharges] =
    useState<number>(0);
  const [sanitizerCharges, setSanitizerCharges] =
    useState<number>(0);
  const [
    vehicleAccesibleCharges,
    setVehicleAccesibleCharges,
  ] = useState<number>(0);
  const [distanceCharges, setDistanceCharges] =
    useState<number>(0);
  const [deliveryCharges, setDeliveryCharges] =
    useState<number>(0);
  const [afterHourCharges, setAfterHourCharges] =
    useState<number>(0);
  const [additionalCharges, setAdditionalCharges] =
    useState<number>(0);
  const [likeNewPackageCharges, setLikeNewPackageCharges] =
    useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const params = useParams<{ id: string }>();
  const { loading, data, error, refetch } =
    useGetQuoteDetailsQuery({
      variables: {
        quoteId: params.id!,
      },
    });
  const {
    loading: basePriceLoading,
    data: basePriceData,
    error: basePriceError,
  } = useGetBasePriceQuery();
  const updateQuoteDetails =
    useUpdateQuoteDetailsMutation();
  const sendQuote = useCreateSendQuoteMutation();

  console.log(data);

  const disabled =
    data?.getQuoteDetails.UserOrder.length! > 0;

  useEffect(() => {
    if (data) {
      setPortaPottyQty(
        data.getQuoteDetails.product_porta_porty || 0
      );
      setAdaQty(data.getQuoteDetails.product_ADA || 0);
      setSinksQty(
        data.getQuoteDetails.product_handwashSinks || 0
      );
      setHandwashStationQty(
        data.getQuoteDetails.product_handwashSanitizers || 0
      );
      setDiscountPer(data.getQuoteDetails.discount || 0);
    }
    // portapotty charges
    if (
      data?.getQuoteDetails.porta_porty_charges === null
    ) {
      if (
        data?.getQuoteDetails.job_Site === JobSite.Event
      ) {
        setPortaPottyCharges(
          data.getQuoteDetails.product_porta_porty! *
            basePriceData?.getBasePrice.Porta_potty_Events!
        );
      } else {
        setPortaPottyCharges(
          data.getQuoteDetails.product_porta_porty! *
            basePriceData?.getBasePrice
              .Porta_potty_Construction!
        );
      }
    } else {
      setPortaPottyCharges(
        data?.getQuoteDetails.porta_porty_charges!
      );
    }
    //ada charges
    // if (
    //   data?.getQuoteDetails.ADA_charges === null
    // ) {
    if (data?.getQuoteDetails.job_Site === JobSite.Event) {
      setAdaCharges(
        data.getQuoteDetails.product_ADA! *
          basePriceData?.getBasePrice
            .Restroom_trailers_Events!
      );
    }
    //  else {
    //   setAdaCharges(
    //     data.getQuoteDetails.product_ADA! *
    //       basePriceData?.getBasePrice
    //         .Restroom_trailers_Constructions!
    //   );
    // }
    // } else {
    //   setAdaCharges(
    //     data?.getQuoteDetails.ADA_charges!
    //   );
    // }
    //sinks charges
    if (
      data?.getQuoteDetails.handwash_sinks_charges === null
    ) {
      if (
        data?.getQuoteDetails.job_Site === JobSite.Event
      ) {
        setSinksCharges(
          data.getQuoteDetails.product_handwashSinks! *
            basePriceData?.getBasePrice
              .Handwash_stations_Events!
        );
      } else {
        setSinksCharges(
          data.getQuoteDetails.product_handwashSinks! *
            basePriceData?.getBasePrice
              .Handwash_stations_Construction!
        );
      }
    } else {
      setSinksCharges(
        data?.getQuoteDetails.handwash_sinks_charges!
      );
    }
    //sanitizer charges
    if (
      data?.getQuoteDetails.handwash_sanitizers_charges ===
      null
    ) {
      setSanitizerCharges(
        data.getQuoteDetails.product_handwashSanitizers! *
          basePriceData?.getBasePrice
            .Handsanitiser_stations_Events!
      );
    } else {
      setSanitizerCharges(
        data?.getQuoteDetails.handwash_sanitizers_charges!
      );
    }
    // vehicle accesible charges
    if (
      data?.getQuoteDetails.vehicle_accessible_charges ===
      null
    ) {
      setVehicleAccesibleCharges(
        data.getQuoteDetails.vehicleAccessible === false
          ? basePriceData?.getBasePrice
              .Vehicle_accessibility_charges || 0
          : 0
      );
    } else {
      setVehicleAccesibleCharges(
        data?.getQuoteDetails.vehicle_accessible_charges!
      );
    }
    // distance charges
    if (data?.getQuoteDetails.distance_charges === null) {
      let _distance_charges = 0;
      if (
        data.getQuoteDetails.distanceInMiles >= 21 &&
        data.getQuoteDetails.distanceInMiles <= 40
      ) {
        _distance_charges =
          basePriceData?.getBasePrice
            .Distance_charges_Long_range_delivery_and_Pickup ||
          0;
        if (data.getQuoteDetails.additionalServices)
          _distance_charges +=
            (data.getQuoteDetails
              .no_of_additional_services || 0) *
            (basePriceData?.getBasePrice
              .Distance_charges_For_additional_services ||
              0);
      }
      setDistanceCharges(_distance_charges);
    } else {
      setDistanceCharges(
        data?.getQuoteDetails.distance_charges!
      );
    }
    // delivery charges
    if (data?.getQuoteDetails.delivery_charges === null) {
      const weekendDelivery =
        new Date(
          data?.getQuoteDetails.delivery_date
        ).getUTCDay() === 0 ||
        new Date(
          data?.getQuoteDetails.delivery_date
        ).getUTCDay() === 6;
      const afterHoursDelivery =
        new Date(
          data?.getQuoteDetails.from_delivery_time
        ).getUTCHours() < 7 ||
        new Date(
          data?.getQuoteDetails.to_delivery_time
        ).getUTCHours() > 18;
      setDeliveryCharges(
        weekendDelivery || afterHoursDelivery
          ? basePriceData?.getBasePrice
              .After_hour_and_Weekend_delivery_charges || 0
          : 0
      );
    } else {
      setDeliveryCharges(
        data?.getQuoteDetails.delivery_charges!
      );
    }
    //after hour charges
    if (
      data?.getQuoteDetails.after_hour_services_charges ===
      null
    ) {
      if (
        data?.getQuoteDetails.afterHours ||
        data?.getQuoteDetails.weekendServices
      ) {
        let sanitizers =
          data.getQuoteDetails.product_handwashSanitizers! *
          basePriceData?.getBasePrice
            .Afterhour_services_Handsanitiser_station!;
        let sinks =
          data.getQuoteDetails.product_handwashSinks! *
          basePriceData?.getBasePrice
            .Afterhour_services_Handwash_station!;
        let porta =
          data.getQuoteDetails.product_porta_porty! *
          basePriceData?.getBasePrice
            .Afterhour_services_Porta_potty!;
        setAfterHourCharges(sanitizers + sinks + porta);
        console.log(sanitizers, sinks, porta);
      } else {
        setAfterHourCharges(0);
      }
    } else {
      setAfterHourCharges(
        data?.getQuoteDetails.after_hour_services_charges!
      );
    }
    //additional charges
    if (
      data?.getQuoteDetails.additional_services_charges ===
      null
    ) {
      if (data?.getQuoteDetails.additionalServices) {
        let sanitizers =
          data.getQuoteDetails.product_handwashSanitizers! *
          basePriceData?.getBasePrice
            .Additional_services_Handsanitiser_station!;
        let sinks =
          data.getQuoteDetails.product_handwashSinks! *
          basePriceData?.getBasePrice
            .Additional_services_Handwash_station!;
        let porta =
          data.getQuoteDetails.product_porta_porty! *
          basePriceData?.getBasePrice
            .Additional_services_Porta_potty!;
        setAdditionalCharges(sanitizers + sinks + porta);
      } else {
        setAdditionalCharges(0);
      }
    } else {
      setAdditionalCharges(
        data?.getQuoteDetails.additional_services_charges!
      );
    }
    //like new package charges
    if (
      data?.getQuoteDetails.like_new_package_charges ===
      null
    ) {
      if (data?.getQuoteDetails.likeNewPackage) {
        let num =
          data.getQuoteDetails.product_porta_porty! *
          basePriceData?.getBasePrice
            .Like_new_package_Porta_potty!;
        let num1 = 0;
        if (data?.getQuoteDetails.handwashStation) {
          num1 =
            data.getQuoteDetails.product_handwashSinks! *
            basePriceData?.getBasePrice
              .Like_new_package_Handwash_station!;
        }
        setLikeNewPackageCharges(num + num1);
      } else {
        setLikeNewPackageCharges(0);
      }
    } else {
      setLikeNewPackageCharges(
        data?.getQuoteDetails.like_new_package_charges!
      );
    }
    // subtotal
    if (!data?.getQuoteDetails.subtotal === null) {
      setSubtotal(data?.getQuoteDetails.subtotal!);
    }
  }, [basePriceData, data]);

  useEffect(() => {
    console.log(
      portaPottyCharges,
      adaCharges,
      sinksCharges,
      sanitizerCharges,
      vehicleAccesibleCharges,
      distanceCharges,
      deliveryCharges,
      afterHourCharges,
      additionalCharges,
      likeNewPackageCharges
    );
    setSubtotal(
      portaPottyCharges +
        adaCharges +
        sinksCharges +
        sanitizerCharges +
        vehicleAccesibleCharges +
        distanceCharges +
        deliveryCharges +
        afterHourCharges +
        additionalCharges +
        likeNewPackageCharges
    );
  }, [
    adaCharges,
    additionalCharges,
    afterHourCharges,
    deliveryCharges,
    distanceCharges,
    likeNewPackageCharges,
    portaPottyCharges,
    sanitizerCharges,
    sinksCharges,
    vehicleAccesibleCharges,
  ]);

  const showConfirm = async () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <p>Are you sure to send quote?</p>,
      async onOk() {
        try {
          const res = await updateQuoteDetails[0]({
            variables: {
              porta_porty: portaPottyQty,
              ADA: adaQty,
              handwashSinks: sinksQty,
              handwashSanitizers: handwashStationQty,
              discount: discountPer,
              quotesId: data?.getQuoteDetails.id!,
            },
          });
          if (res.data?.updateQuoteDetails) {
            refetch();
            const res = await sendQuote[0]({
              variables: {
                quoteId: data?.getQuoteDetails.id!,
              },
            });
            if (res.data?.createSendQuote.id) {
              successModal();
            }
          }
        } catch (error) {
          errorModal();
        }
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const successModal = () => {
    Modal.success({
      title: "Success!",
      content: "Quote sent successfully",
    });
  };

  const errorModal = () => {
    Modal.error({
      title: "Error!",
      content: "Quote has already submitted!",
    });
  };

  return (
    <CustomLayout defaultSelectedKey="1">
      <div style={{ margin: 50 }}>
        {loading || basePriceLoading ? <Spin /> : null}
        {error || basePriceError ? (
          <Typography>
            {error?.message || basePriceError?.message}
          </Typography>
        ) : null}
        {!loading &&
        !error &&
        !basePriceLoading &&
        !basePriceError ? (
          <>
            <Card>
              <Row gutter={8}>
                <Col span={12}>
                  <Title level={4}>
                    Customer information
                  </Title>
                  <p>
                    Name:{" "}
                    {data?.getQuoteDetails.User.full_name}
                  </p>
                  <p>
                    Email:{" "}
                    {data?.getQuoteDetails.User.email}
                  </p>
                  <p>
                    Phone:{" "}
                    {
                      data?.getQuoteDetails.User
                        .phone_number
                    }
                  </p>
                </Col>
                <Col span={12}>
                  <Title level={4}>
                    Quote information{" "}
                  </Title>
                  <p>Id: {data?.getQuoteDetails.id}</p>
                  <p>
                    Date:{" "}
                    {dayjs(data?.getQuoteDetails.createdAt)
                      .utc()
                      .format("MM-DD-YYYY")}
                  </p>
                  <p>
                    Status:{" "}
                    {data?.getQuoteDetails.UserOrder
                      .length! > 0
                      ? data?.getQuoteDetails.UserOrder[0]
                          .status
                      : ""}
                  </p>
                  <p>
                    Order id:{" "}
                    {data?.getQuoteDetails.UserOrder
                      .length! > 0
                      ? data?.getQuoteDetails.UserOrder[0]
                          .id
                      : ""}
                  </p>
                </Col>
              </Row>
            </Card>
            <Card>
              <Title level={4}>Quote details</Title>
              <Row gutter={8}>
                <Col span={8}>
                  <p>
                    Service sector:{" "}
                    {data?.getQuoteDetails.service_Sector}
                  </p>
                  <p>
                    Job site:{" "}
                    {data?.getQuoteDetails.job_Site}
                  </p>
                  {data?.getQuoteDetails.job_Site ===
                  JobSite.Construction ? (
                    <>
                      <p>
                        Type:{" "}
                        {data.getQuoteDetails
                          .construction_type
                          ? data.getQuoteDetails
                              .construction_type
                          : ""}
                      </p>
                      <p>
                        High rise:{" "}
                        {data.getQuoteDetails
                          .construction_highRise
                          ? "true"
                          : "false"}
                      </p>
                    </>
                  ) : null}
                  {data?.getQuoteDetails.job_Site ===
                  JobSite.Event ? (
                    <>
                      <p>
                        Type of event:{" "}
                        {data.getQuoteDetails.event_value
                          ? data.getQuoteDetails.event_value
                          : ""}
                      </p>
                      <p>
                        Vip accomodation:{" "}
                        {data.getQuoteDetails
                          .event_vipAccommodation
                          ? "true"
                          : "false"}
                      </p>
                    </>
                  ) : null}
                  <p>
                    Vehicle accessible:{" "}
                    {data?.getQuoteDetails.vehicleAccessible
                      ? "true"
                      : "false"}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    Duration:{" "}
                    {dayjs(data?.getQuoteDetails.startDate)
                      .utc()
                      .format("MM-DD-YYYY")}{" "}
                    to{" "}
                    {dayjs(data?.getQuoteDetails.endDate)
                      .utc()
                      .format("MM-DD-YYYY")}
                  </p>
                  <p>
                    Hours of use:{" "}
                    {dayjs(data?.getQuoteDetails.startHour)
                      .utc()
                      .format("h:mm A")}{" "}
                    to{" "}
                    {dayjs(data?.getQuoteDetails.endHour)
                      .utc()
                      .format("h:mm A")}
                  </p>
                  <p>
                    No of attendees:{" "}
                    {data?.getQuoteDetails.no_of_attendees}
                  </p>
                  <p>
                    Zip code:{" "}
                    {data?.getQuoteDetails.zipcode}
                  </p>
                  <p>
                    Delivery Date:{" "}
                    {dayjs(
                      data?.getQuoteDetails.delivery_date
                    )
                      .utc()
                      .format("MM-DD-YYYY")}{" "}
                    (
                    {dayjs(
                      data?.getQuoteDetails
                        .from_delivery_time
                    )
                      .utc()
                      .format("h:mm A")}{" "}
                    to{" "}
                    {dayjs(
                      data?.getQuoteDetails.to_delivery_time
                    )
                      .utc()
                      .format("h:mm A")}
                    )
                  </p>
                  <p>
                    After hours:{" "}
                    {data?.getQuoteDetails.afterHours
                      ? "true"
                      : "false"}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    Weekend services:{" "}
                    {data?.getQuoteDetails.weekendServices
                      ? "true"
                      : "false"}
                  </p>
                  <p>
                    Additional services:{" "}
                    {data?.getQuoteDetails
                      .additionalServices
                      ? "true"
                      : "false"}
                  </p>
                  {data?.getQuoteDetails
                    .additionalServices ? (
                    <p>
                      No Of Additional services:{" "}
                      {
                        data.getQuoteDetails
                          .no_of_additional_services
                      }
                    </p>
                  ) : null}
                  <p>
                    Like new package:{" "}
                    {data?.getQuoteDetails.likeNewPackage
                      ? "true"
                      : "false"}
                  </p>
                  <p>
                    Handwash station:{" "}
                    {data?.getQuoteDetails.handwashStation
                      ? "true"
                      : "false"}
                  </p>
                  {data?.getQuoteDetails.handwashStation ? (
                    <p>
                      Handwash station:{" "}
                      {data.getQuoteDetails.stationType}
                    </p>
                  ) : null}
                  {data?.getQuoteDetails.isMultiHandSink ? (
                    <p>
                      Multi-User Hand Sink:{" "}
                      {data.getQuoteDetails.isMultiHandSink
                        ? "Yes"
                        : "No"}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col span={24}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th
                          style={{
                            width: "400px",
                          }}
                        >
                          Quantity
                        </th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Porta potty</td>
                        <td>
                          <Input
                            type="number"
                            disabled={disabled}
                            onChange={async (e) => {
                              if (
                                parseInt(e.target.value) >=
                                0
                              ) {
                                setPortaPottyQty(
                                  parseInt(e.target.value)
                                );
                                if (
                                  data?.getQuoteDetails
                                    .job_Site ===
                                  JobSite.Event
                                ) {
                                  setPortaPottyCharges(
                                    parseInt(
                                      e.target.value
                                    ) *
                                      basePriceData
                                        ?.getBasePrice
                                        .Porta_potty_Events!
                                  );
                                } else {
                                  setPortaPottyCharges(
                                    parseInt(
                                      e.target.value
                                    ) *
                                      basePriceData
                                        ?.getBasePrice
                                        .Porta_potty_Construction!
                                  );
                                }
                                await updateQuoteDetails[0](
                                  {
                                    variables: {
                                      porta_porty: parseInt(
                                        e.target.value
                                      ),
                                      ADA: adaQty,
                                      handwashSinks:
                                        sinksQty,
                                      handwashSanitizers:
                                        handwashStationQty,
                                      discount: discountPer,
                                      quotesId:
                                        data
                                          ?.getQuoteDetails
                                          .id!,
                                    },
                                  }
                                );
                              }
                            }}
                            value={portaPottyQty}
                          />
                        </td>
                        <td>${portaPottyCharges}</td>
                      </tr>
                      {/* event type */}
                      {data?.getQuoteDetails.job_Site ===
                      JobSite.Event ? (
                        <>
                          <tr>
                            <td>Ada</td>
                            <td>
                              <Input
                                type="number"
                                disabled={disabled}
                                onChange={async (e) => {
                                  if (
                                    parseInt(
                                      e.target.value
                                    ) >= 0
                                  ) {
                                    setAdaQty(
                                      parseInt(
                                        e.target.value
                                      )
                                    );
                                    if (
                                      data?.getQuoteDetails
                                        .job_Site ===
                                      JobSite.Event
                                    ) {
                                      setAdaCharges(
                                        parseInt(
                                          e.target.value
                                        ) *
                                          basePriceData
                                            ?.getBasePrice
                                            .Restroom_trailers_Events!
                                      );
                                    } else {
                                      setAdaCharges(
                                        parseInt(
                                          e.target.value
                                        ) *
                                          basePriceData
                                            ?.getBasePrice
                                            .Restroom_trailers_Constructions!
                                      );
                                    }
                                    await updateQuoteDetails[0](
                                      {
                                        variables: {
                                          porta_porty:
                                            portaPottyQty,
                                          ADA: parseInt(
                                            e.target.value
                                          ),
                                          handwashSinks:
                                            sinksQty,
                                          handwashSanitizers:
                                            handwashStationQty,
                                          discount:
                                            discountPer,
                                          quotesId:
                                            data
                                              ?.getQuoteDetails
                                              .id!,
                                        },
                                      }
                                    );
                                  }
                                }}
                                value={adaQty}
                              />
                            </td>
                            <td>${adaCharges}</td>
                          </tr>
                          <tr>
                            <td>Handwash Sinks</td>
                            <td>
                              <Input
                                type="number"
                                disabled={disabled}
                                onChange={async (e) => {
                                  if (
                                    parseInt(
                                      e.target.value
                                    ) >= 0
                                  ) {
                                    setSinksQty(
                                      parseInt(
                                        e.target.value
                                      )
                                    );
                                    setSinksCharges(
                                      parseInt(
                                        e.target.value
                                      ) *
                                        basePriceData
                                          ?.getBasePrice
                                          .Handwash_stations_Events!
                                    );
                                    await updateQuoteDetails[0](
                                      {
                                        variables: {
                                          porta_porty:
                                            portaPottyQty,
                                          ADA: adaQty,
                                          handwashSinks:
                                            parseInt(
                                              e.target.value
                                            ),
                                          handwashSanitizers:
                                            handwashStationQty,
                                          discount:
                                            discountPer,
                                          quotesId:
                                            data
                                              ?.getQuoteDetails
                                              .id!,
                                        },
                                      }
                                    );
                                  }
                                }}
                                value={sinksQty}
                              />
                            </td>
                            <td>${sinksCharges}</td>
                          </tr>
                        </>
                      ) : null}
                      {/* event handwash station */}
                      {data?.getQuoteDetails
                        .handwashStation &&
                      data?.getQuoteDetails.job_Site ===
                        JobSite.Event ? (
                        <tr>
                          <td>
                            Hand sanitizers/Multi-User Hand
                            Sinks
                          </td>
                          <td>
                            <Input
                              type="number"
                              disabled={disabled}
                              onChange={async (e) => {
                                if (
                                  parseInt(
                                    e.target.value
                                  ) >= 0
                                ) {
                                  setHandwashStationQty(
                                    parseInt(e.target.value)
                                  );
                                  setSanitizerCharges(
                                    parseInt(
                                      e.target.value
                                    ) *
                                      basePriceData
                                        ?.getBasePrice
                                        .Handsanitiser_stations_Events!
                                  );
                                  await updateQuoteDetails[0](
                                    {
                                      variables: {
                                        porta_porty:
                                          portaPottyQty,
                                        ADA: adaQty,
                                        handwashSinks:
                                          sinksQty,
                                        handwashSanitizers:
                                          parseInt(
                                            e.target.value
                                          ),
                                        discount:
                                          discountPer,

                                        quotesId:
                                          data
                                            ?.getQuoteDetails
                                            .id!,
                                      },
                                    }
                                  );
                                }
                              }}
                              value={handwashStationQty}
                            />
                          </td>
                          <td>${sanitizerCharges}</td>
                        </tr>
                      ) : null}
                      {/* construction handwash station */}
                      {data?.getQuoteDetails
                        .handwashStation &&
                      data?.getQuoteDetails.job_Site ===
                        JobSite.Construction ? (
                        <tr>
                          <td>Handwash Sinks</td>
                          <td>
                            <Input
                              type="number"
                              disabled={disabled}
                              onChange={async (e) => {
                                if (
                                  parseInt(
                                    e.target.value
                                  ) >= 0
                                ) {
                                  setSinksQty(
                                    parseInt(e.target.value)
                                  );
                                  setSinksCharges(
                                    parseInt(
                                      e.target.value
                                    ) *
                                      basePriceData
                                        ?.getBasePrice
                                        .Handwash_stations_Construction!
                                  );
                                  await updateQuoteDetails[0](
                                    {
                                      variables: {
                                        porta_porty:
                                          portaPottyQty,
                                        ADA: adaQty,
                                        handwashSinks:
                                          parseInt(
                                            e.target.value
                                          ),
                                        handwashSanitizers:
                                          handwashStationQty,
                                        discount:
                                          discountPer,

                                        quotesId:
                                          data
                                            ?.getQuoteDetails
                                            .id!,
                                      },
                                    }
                                  );
                                }
                              }}
                              value={sinksQty}
                            />
                          </td>
                          <td>${sinksCharges}</td>
                        </tr>
                      ) : null}
                      {!data?.getQuoteDetails
                        .vehicleAccessible ? (
                        <tr>
                          <td>Vehicle accesible</td>
                          <td></td>
                          <td>
                            ${vehicleAccesibleCharges}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Distance charges</td>
                        <td></td>
                        <td>${distanceCharges}</td>
                      </tr>
                      <tr>
                        <td>Delivery charges</td>
                        <td></td>
                        <td>${deliveryCharges}</td>
                      </tr>
                      {data?.getQuoteDetails.afterHours ||
                      data?.getQuoteDetails
                        .weekendServices ? (
                        <tr>
                          <td>After hours services</td>
                          <td></td>
                          <td>${afterHourCharges}</td>
                        </tr>
                      ) : null}
                      {data?.getQuoteDetails
                        .additionalServices ? (
                        <tr>
                          <td>
                            Additional services charges
                          </td>
                          <td></td>
                          <td>${additionalCharges}</td>
                        </tr>
                      ) : null}
                      {data?.getQuoteDetails
                        .likeNewPackage ? (
                        <tr>
                          <td>Like new package</td>
                          <td></td>
                          <td>${likeNewPackageCharges}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td></td>
                        <td>Subtotal</td>
                        <td>${subtotal}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td
                          style={{
                            display: "inline-flex",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              marginRight: 10,
                            }}
                          >
                            Discount(%){" "}
                          </p>
                          <Input
                            type="number"
                            disabled={disabled}
                            onChange={async (e) => {
                              if (
                                parseInt(e.target.value) >=
                                0
                              ) {
                                setDiscountPer(
                                  parseInt(e.target.value)
                                );
                                await updateQuoteDetails[0](
                                  {
                                    variables: {
                                      porta_porty:
                                        portaPottyQty,
                                      ADA: adaQty,
                                      handwashSinks:
                                        sinksQty,
                                      handwashSanitizers:
                                        handwashStationQty,
                                      discount: parseInt(
                                        e.target.value
                                      ),
                                      quotesId:
                                        data
                                          ?.getQuoteDetails
                                          .id!,
                                    },
                                  }
                                );
                              }
                            }}
                            value={discountPer}
                          />
                        </td>
                        <td>
                          $
                          {(
                            (discountPer / 100) *
                            subtotal
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Total</td>
                        <td>
                          $
                          {subtotal -
                            (discountPer / 100) * subtotal}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Button
                type="primary"
                style={{
                  marginTop: 20,
                  float: "right",
                }}
                disabled={disabled}
                onClick={showConfirm}
              >
                Send Quote
              </Button>
            </Card>
          </>
        ) : null}
      </div>
    </CustomLayout>
  );
};

export default Quote;
