import { Row, Col, Card, Typography, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";

import CustomLayout from "../components/CustomLayout";
import { useGetOrderDetailsQuery } from "../hooks/graphql/graphql";
const { Title } = Typography;

const Order = () => {
  const params = useParams<{ id: string }>();
  const { loading, error, data } = useGetOrderDetailsQuery({
    variables: { orderId: params.id! },
  });

  return (
    <CustomLayout defaultSelectedKey="3">
      <div style={{ margin: 50 }}>
        {loading ? <Spin /> : null}
        {error ? <Typography>{error.message}</Typography> : null}
        {!loading && !error ? (
          <Card>
            <Row gutter={8}>
              <Col span={12}>
                <Title level={4}>Customer information</Title>
                <p>Name: {data?.getOrderDetails.user.full_name}</p>
                <p>Email: {data?.getOrderDetails.user.email}</p>
                <p>Phone: {data?.getOrderDetails.user.phone_number}</p>
                <p>
                  Shipping address:{" "}
                  {data?.getOrderDetails.customerAddress?.address_1 +
                    " " +
                    data?.getOrderDetails.customerAddress?.address_2 +
                    ", " +
                    data?.getOrderDetails.customerAddress?.city_town +
                    " " +
                    data?.getOrderDetails.customerAddress?.zipcode}
                </p>
                <p>Status: {data?.getOrderDetails.status}</p>
              </Col>
              <Col span={12}>
                <Title level={4}>Order information </Title>
                <p>Order number: {data?.getOrderDetails.id}</p>
                <p>
                  Date:{" "}
                  {dayjs(data?.getOrderDetails.createdAt).format("MM-DD-YYYY")}
                </p>
                <p>
                  Quote id:{" "}
                  <Link to={`/quote/${data?.getOrderDetails.userQuoteId}`}>
                    {data?.getOrderDetails.userQuoteId}
                  </Link>
                </p>
                <p>Order total: ${data?.getOrderDetails.amount}</p>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </CustomLayout>
  );
};

export default Order;
