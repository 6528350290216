import { useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.png";
import { useForgotPasswordMutation } from "../hooks/graphql/graphql";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const StyledContainer = styled.div`
  margin-top: 25px;
  text-align: left;
  width: 25rem;
  padding: 38px 30px 25px;
  border-radius: 2px;
  border-top: 2px solid #fc5000;
  box-shadow: rgb(227 233 243) 0px 2px 4px 0px;
`;
const StyledImg = styled.img`
  margin-top: 76px;
`;

const ForgotPassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const forgotPassword = useForgotPasswordMutation();

  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      const { data } = await forgotPassword[0]({
        variables: { email: values.email },
      });
      console.log(data);
      if (!data?.forgotPasswordWithEmailOTP.status) {
        setError(data?.forgotPasswordWithEmailOTP.message!);
      } else {
        navigate(`/otp/${values.email}`);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = () => {
    setError("");
  };
  return (
    <StyledWrapper>
      <div>
        <StyledImg src={logo} alt="logo" />
        <StyledContainer>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                addonBefore="@"
                type="email"
                placeholder="name@email.com"
              />
            </Form.Item>
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={submitting}
              >
                {submitting ? "..." : "Send OTP"}
              </Button>
            </Form.Item>
          </Form>
        </StyledContainer>
      </div>
    </StyledWrapper>
  );
};

export default ForgotPassword;
