import { FC } from "react";
import { Dropdown, Layout, Menu, Typography } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

const { Header, Sider } = Layout;
const { Title } = Typography;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledSider = styled(Sider)`
  overflow: auto;
  background: white;
  box-shadow: rgb(227 233 243) 0px 2px 4px 0px;
`;

type Props = {
  defaultSelectedKey: string;
  children: JSX.Element | JSX.Element[];
};

const CustomLayout: FC<Props> = ({ defaultSelectedKey = "1", children }) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <StyledHeader>
        <Title style={{ color: "white", marginTop: 10 }} level={4}>
          #1best Admin
        </Title>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => navigate("/logout")}>Logout</Menu.Item>
            </Menu>
          }
        >
          <Title style={{ color: "white", marginTop: 10 }} level={5}>
            Welcome meghan <DownOutlined />
          </Title>
        </Dropdown>
      </StyledHeader>
      <Layout>
        <StyledSider>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[defaultSelectedKey]}
          >
            <Menu.Item key="1" onClick={() => navigate("/")}>
              Quotes
            </Menu.Item>
            <Menu.Item key="2" onClick={() => navigate("/users")}>
              Users
            </Menu.Item>
            <Menu.Item key="3" onClick={() => navigate("/orders")}>
              Orders
            </Menu.Item>
            <Menu.Item key="4" onClick={() => navigate("/base-price")}>
              Base price
            </Menu.Item>
          </Menu>
        </StyledSider>
        <Layout style={{ minHeight: "calc(100vh - 64px)" }}>{children}</Layout>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
