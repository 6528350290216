import { useEffect, useState } from "react";
import { hasAuthToken, SharedStateAuthKey } from "../services/authToken";
import PubSub from "pubsub-js";

function useAuth() {
  const [auth, setAuth] = useState(hasAuthToken());

  useEffect(() => {
    const token = PubSub.subscribe(SharedStateAuthKey, (_, data: boolean) => {
      setAuth(data);
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  });

  return auth;
}

export default useAuth;
