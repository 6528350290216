import { useState } from "react";
import {
  Typography,
  Table,
  TablePaginationConfig,
  Card,
  Input,
  Button,
  Form,
} from "antd";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FilterValue } from "antd/lib/table/interface";
import { DefaultRecordType } from "rc-table/lib/interface";
import { Link, useNavigate } from "react-router-dom";

import CustomLayout from "../components/CustomLayout";
import { useGetOrderListingQuery } from "../hooks/graphql/graphql";
const { Title } = Typography;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

const Orders = () => {
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [varPage, setVarPage] = useState(0);
  const navigate = useNavigate();
  const { data: orders, loading } = useGetOrderListingQuery({
    variables: {
      searchKeyword: searchInput.length > 0 ? searchInput : "",
      pageNumber: varPage,
    },
  });
  const [form] = Form.useForm();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setPage(pagination?.current!);
    setVarPage((pagination?.current! - 1) * 10);
  };

  const handleSearch = () => {
    setPage(1);
    setVarPage(0);
    setSearchInput(
      form.getFieldValue("search") ? form.getFieldValue("search") : ""
    );
  };

  const handleReset = async () => {
    setPage(1);
    setVarPage(0);
    setSearchInput("");
    form.setFieldsValue({ search: "" });
  };

  const handleView = (record: DefaultRecordType) => {
    navigate(`/order/${record.order_id}`);
  };

  const columns = [
    {
      title: "Quote Id",
      dataIndex: "quote_id",
      key: "quote_id",
      render: (text: any, record: DefaultRecordType) => (
        <Link to={`/quote/${record.quote_id}`}>{record.quote_id}</Link>
      ),
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      render: (text: any, record: DefaultRecordType) => (
        <Link to={`/order/${record.order_id}`}>{record.order_id}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Submitted Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: DefaultRecordType) => (
        <p style={{ cursor: "pointer" }} onClick={() => handleView(record)}>
          View
        </p>
      ),
    },
  ];

  const data = orders?.getOrderListing.orders?.map((order) => ({
    key: order.id,
    quote_id: order.userQuoteId,
    order_id: order.id,
    name: order.user.full_name,
    date: dayjs(new Date(parseInt(order.createdAt))).format("MM-DD-YYYY"),
  }));

  return (
    <CustomLayout defaultSelectedKey="3">
      <Card style={{ margin: 50 }}>
        <StyledDiv>
          <Title level={5}>Orders Table</Title>
          <InputWrapper>
            <Form form={form}>
              <Form.Item label="" name="search">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  type={"text"}
                />
              </Form.Item>
            </Form>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button type="default" onClick={handleReset}>
              Reset
            </Button>
          </InputWrapper>
        </StyledDiv>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            current: page,
            pageSize: 10,
            total: orders?.getOrderListing.ordersCount,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </CustomLayout>
  );
};

export default Orders;
